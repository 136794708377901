import React from "react";
import { Helmet } from "react-helmet";
import Main from "@templates/Main";

import Chat from "@organisms/Chat";

import { general } from "@content/general";

const PageTemp = () => {
  return (
    <Main>
      <Chat />
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={general.title}
        meta={[
          {
            name: `description`,
            content: general.description,
          },
          {
            property: `og:title`,
            content: general.title,
          },
          {
            property: `og:description`,
            content: general.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: general.author,
          },
          {
            name: `twitter:title`,
            content: general.title,
          },
          {
            name: `twitter:description`,
            content: general.description,
          },
        ].concat(
          general.keywords.length > 0
            ? {
                name: `keywords`,
                content: general.keywords.join(`, `),
              }
            : []
        )}
      />
    </Main>
  );
};

export default PageTemp;
